const config = {
    brand: {
        name: ['De','Kantoorartikelen','Webstore'],
        imgSrc: './frontpage.jpg'
    }, 
    account: {
        imgSrc: './accountpage.jpg'
    },
    cards: [
        {
            imgSrc: './news.jpg',
            title: 'Gekleurde paperclips',
            subtitle: 'Bundel je aantekeningen met gekleurde paperclips van ons huismerk.'
        }
    ]
}

export { config }